.boxWrapper{
    display: flex;
    flex-direction: row;
}
.monthSelect {
    margin-top: 26px;
    margin-bottom: 40px;
    margin-left: 25px;
}
.fieldWidth{
    max-Width: 100%;
}
